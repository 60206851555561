<template>
  <div>
    <b-modal
      id="modal-center"
      ref="gd-ancient-pop-detail-modal"
      hide-footer
      hide-header
      scrollable
      centered
      @hidden="hiddenModal"
      size="lg"
    >
      <div class="modal-close-container">
        <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
      </div>
      <b-row v-if="dataSample">
        <b-col cols="12" xl="12" md="12">
          <h5>{{ dataSample.title }}</h5>
        </b-col>
      </b-row>
      <b-row v-if="dataSample" class="model-content-area cs-scroll-style">
        <b-col cols="12" xl="6" md="6">
          <div class="image-container">
            <b-img
              class="mx-auto detail-image"
              :src="dataSample.logoUrl"
            />
          </div>
        </b-col>
        <b-col cols="12" xl="6" md="6">
          <div class="title-header">
            <h4>{{ 'Genetically Closest Populations' }}</h4>
          </div>
          <hr />
          <div class="breakdown-container">
            <div class="breakdown-body text-font-size">
              <b-table
                :fields="fields"
                :items="distanceResult"
                responsive="sm"
                head-variant="light"
                small
                bordered
                outlined
              >

                <template #cell(title)="data">
                  <span> 
                    {{ data.item.title.replaceAll('*', ' ') }}
                  </span>
                </template>
                <template #cell(distance)="data">
                  <span :style="{ color: data.item.color }"> {{ data.item.distance }}</span>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
        
        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ 'Description' }}</h4>
          </div>
          <hr />
          <div class="text-font-size">
            <b-card-text v-html="dataSample.desciption1"></b-card-text>
          </div>
        </b-col>

        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ 'Meta' }}</h4>
          </div>
          <hr />
          <div class="text-font-size">
            <b-card-text class="" >
                <feather-icon icon="CalendarIcon" size="18" class="date" color= "#fa7d09"/> <b style="color: #fa7d09">Date:</b> {{dataSample.date}}
            </b-card-text>
            <b-card-text class="mt-2">
                <svg height="18pt" class="ext-primary" viewBox="0 0 384 384" width="14pt" xmlns="http://www.w3.org/2000/svg"><path fill="#00ADB5" d="m383.792969 13.9375c-.175781-1.378906-.480469-2.707031-.984375-3.953125-.015625-.03125-.015625-.074219-.023438-.113281 0-.007813-.007812-.015625-.015625-.023438-.554687-1.3125-1.3125-2.503906-2.167969-3.609375-.210937-.261719-.417968-.519531-.640624-.765625-.914063-1.03125-1.90625-1.984375-3.058594-2.761718-.03125-.023438-.070313-.03125-.101563-.054688-1.113281-.734375-2.34375-1.289062-3.632812-1.726562-.320313-.113282-.632813-.210938-.960938-.296876-1.351562-.367187-2.742187-.632812-4.207031-.632812h-112c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h73.367188l-95.496094 95.496094c-25.464844-20.367188-56.816406-31.496094-89.871094-31.496094-79.398438 0-144 64.601562-144 144s64.601562 144 144 144 144-64.601562 144-144c0-33.039062-11.121094-64.382812-31.503906-89.871094l95.503906-95.503906v73.375c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-112c0-.335938-.078125-.65625-.097656-.984375-.023438-.367187-.0625-.71875-.109375-1.078125zm-239.792969 338.0625c-61.761719 0-112-50.238281-112-112s50.238281-112 112-112c29.902344 0 58.054688 11.640625 79.222656 32.734375 21.136719 21.210937 32.777344 49.363281 32.777344 79.265625 0 61.761719-50.238281 112-112 112zm0 0"/></svg> 
                <b style="color: #00ADB5;margin-left:7px;">Y-DNA:</b> {{dataSample.yDna}}
            </b-card-text>
            <b-card-text class="mt-2">
                <svg height="18pt" class="ext-primary" viewBox="0 0 512 512" width="16pt" xmlns="http://www.w3.org/2000/svg">
                <path style="fill:#f76a8c;" d="M318.841,402.801h-30.905v46.358h30.905c12.801,0,23.179-10.378,23.179-23.179
                  S331.642,402.801,318.841,402.801z"/>
                <path style="fill:#f76a8c;" d="M425.98,169.98C425.98,76.103,349.877,0,256,0S86.02,76.103,86.02,169.98
                  c0,86.014,63.891,157.093,146.801,168.401v64.42h-39.662c-12.801,0-23.179,10.378-23.179,23.179s10.378,23.179,23.179,23.179h39.662
                  v39.662C232.821,501.622,243.199,512,256,512c12.801,0,23.179-10.378,23.179-23.179v-39.662h8.757
                  c12.801,0,23.179-10.378,23.179-23.179s-10.378-23.179-23.179-23.179h-8.757v-64.42C362.089,327.073,425.98,255.994,425.98,169.98z
                  M256,293.602c-68.274,0-123.622-55.348-123.622-123.622S187.726,46.358,256,46.358s123.622,55.348,123.622,123.622
                  S324.274,293.602,256,293.602z"/>
                </svg> <b style="color: #f76a8c">mtDNA:</b> {{dataSample.mtDna}}
            </b-card-text>
            <b-card-text class="mt-2">
                <feather-icon icon="MapPinIcon" size="18" class="location" color= "#8B5E83"/> <b style="color: #8B5E83">Location:</b> {{dataSample.location}}
            </b-card-text>
            <b-card-text class="mt-2" v-if="researchArray">
              <div style="margin-right: 5px; float: left;">
                <feather-icon icon="SearchIcon" size="18" class="research" color="#61B15A"/> <b style="color: #61B15A">Research: </b>
              </div>
              <div v-html="researchArray"></div>
            </b-card-text>
            <b-card-text class="mt-2">
                <feather-icon icon="DatabaseIcon" size="18" class="research" color="#FFB129"/> <b style="color: #FFB129">Sample(s):</b> {{dataSample.samples}}
            </b-card-text>
          </div>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService';
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {
          
        }
      },
    },
  },
  data() {
    return {
      isModernDist: true,

      dataSample: null,
      distanceResult: [],
      fields: [
        {
          // A virtual column with custom formatter
          key: 'distance',
          label: 'distance',
        },
        {
          // A regular column with custom formatter
          key: 'title',
          label: 'Modern Population',
        },
      ],
    }
  },
  methods: {
    async initialModal() {
      this.$refs['gd-ancient-pop-detail-modal'].show();
      await this.$store.dispatch('ancientProxResult/fetchAncientProxDetail', this.modalObject.item.id);
      this.dataSample = this.$store.state.ancientProxResult.openedAncientProxDetail;
      await this.runDistance();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['gd-ancient-pop-detail-modal'].hide();
    },

    async runDistance() {
      try {
        const dataVersion = this.$store.getters['daResult/dataVersion'];
        const targetSourceDatas = await this.$store.dispatch('ancientProxSourceData/fetchAncientProxSourceData', dataVersion);
        
        const resolveAncientSource = CalculateFMCService.codeDataToArray(targetSourceDatas.sourceData);
        
        const targetSourceData = resolveAncientSource.result.filter(x => x[0] === this.modalObject.item.sourceCode);
        
        CalculateFMCService.onSetTarget(targetSourceData);

        const sourceDataResult = await this.$store.dispatch('modernProxSourceData/fetchModernProxSourceData', dataVersion);
        
        const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
        if (resolveSource.isSuccess) {
          CalculateFMCService.onSetSource(resolveSource.result);
        }

        CalculateFMCService.dataObject.maxDistOutPut = 10;
        const calculatedResult = await CalculateFMCService.calculateDistance(0);
        this.distanceResult = calculatedResult;
      } catch(error) {
        console.warn(error);
      }
    },
  },
  computed: {
    researchArray() {
      const research = this.dataSample.research;
      if (research) {
        const pattern = /<\/?[a-z][\s\S]*>/i;
        if (pattern.test(research)) {
          return research;
        } else {
          const researchArray = research ? research.split('\n') : [];
          return researchArray
                          .map((res) => `<a href="${res}" class="researchlink" target="_blank">${res}</a>`)
                          .join(", ");
        }
      }
      return null;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  margin: 1.75rem auto;
  overflow: auto;
}
.model-content-area {
  overflow: auto;
  overflow-x: hidden;
  height: 85vh;
}
.modal-dialog {
  height: 85vh!important;
}
.modal-body {
  background: #ccc !important;
  overflow: hidden;
  overflow-y: scroll!important;
}
.pop-detail-modal-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.pop-detail-modal-left {
  width: 40%;
}
.pop-detail-modal-right {
  width: 60%;
  max-height: 35vh;
  overflow: auto;
}
.sub-pop-view-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-close-container {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}

.image-container {
  padding: 1rem 0.5rem;
  border-radius: 10px;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 260px !important;
    width: 260px !important;
    border-radius: 10px;
  }
}

.breakdown-container {
  margin-top: 10px;

  .breakdown-body {
    .breakdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      .pop-text {
        font-size: 1rem;
        font-weight: bold;
        color:var(--lab-text-color-title-02);
      }

      .value-text {
        display: flex;
        align-items: center;
      
        span {
          font-size: 1rem;
          font-weight: bold;
          color:var(--lab-text-color-title-02);
          margin-right: 10px;
        }
      }
    }
  }
}

.text-font-size {
  font-size: 0.85rem !important;
  padding: 0.4rem 1rem 1rem 1rem !important;
}
</style>
